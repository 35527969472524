<template>
  <div class="font-body flex w-screen h-screen">
    <!-- map -->

    <div class="w-1/2 h-screen relative">
      <div id="map" class="w-full h-full"></div>
      <!-- overlay -->
      <div class="absolute top-0 right-0 w-full h-full pointer-events-none">
        <!-- selected entity -->
        <div
            v-if="selectedEntity"
            class="absolute flex flex-col gap-2 top-5 left-5 pointer-events-auto bg-white rounded-md">
          <div class="flex flex-col gap-2 pb-4 round w-[350px]">
            <entity-component :entity="selectedEntity"></entity-component>
          </div>
        </div>

        <div
            class="absolute flex flex-col gap-2 top-5 right-5 pointer-events-auto">
          <div
              class="flex flex-col text-black drop-shadow-lg pointer-events-auto">
            <!-- zoom in -->
            <button
                aria-label="die Kartenansicht vergrößern"
                class="flex flex-row justify-center items-center p-2 rounded-t-md border-b border-gray-300 bg-white text-center cursor-pointer h-10 w-10"
                @click="zoomIn">
              <i class="fa-solid fa-plus fa-lg"></i>
            </button>

            <!-- zoom out -->
            <button
                aria-label="die Kartenansicht verkleinern"
                class="flex flex-row justify-center items-center p-2 bg-white text-center cursor-pointer h-10 w-10 border-b border-gray-300 rounded-b-md"
                @click="zoomOut">
              <i class="fa-solid fa-minus fa-lg"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- info container -->
    <!-- w-3/5 => 40% -->
    <div class="w-1/2 h-screen flex flex-col gap-4 p-6">

      <!-- top content -->
      <div class="flex flex-row gap-4 h-[20%]">
        <!-- image -->
        <img
            src="@/assets/annafest_logo.svg"
            alt="Annafest Logo" class="h-full">

        <!-- attractions -->
        <div class="flex flex-col gap-2 border-4 border-ride rounded-2xl p-4 flex-grow overflow-z-scroll">
          <!-- title -->
          <div class="flex items-end gap-2">
            <h1 class="font-heading font-bold text-4xl text-ride">Fahrspass</h1>
            <img src="@/assets/icons/ride.png" alt="Fahrspass" class="w-12">
          </div>

          <!-- content -->
          <div class="grid grid-rows-4 grid-flow-col  flex-wrap overflow-z-scroll h-full">
            <div v-for="(entity) of getCategory('ride')?.entities"
                 :key="entity._id"
                 :class="{'underline font-bold': selectedEntity === entity._id}"
                 @click="highlight(entity)"
            >
              {{ entity.name }}
            </div>
          </div>
        </div>
      </div>

      <!-- bottom content -->
      <div class="grid grid-cols-3 gap-4 flex-grow" style="height: calc(80% - 1rem)">
        <!-- <div class="flex flex-row gap-4 flex-grow"> -->
        <!-- beer -->
        <div class="flex flex-col border-4 border-beer rounded-2xl p-4 overflow-hidden">
          <!-- title -->
          <div class="flex items-end gap-2">
            <h1 class="font-heading font-bold text-4xl text-beer">Bierkeller</h1>
            <img src="@/assets/icons/beer.png" class="w-12">
          </div>

          <!-- content -->
          <div class="flex flex-col gap-1 overflow-y-scroll">
            <div v-for="(entity) of getCategory('beer')?.entities"
                 :key="entity._id"
                 :class="{'underline font-bold': selectedEntity === entity._id}"
                 @click="highlight(entity)"
            >
              {{ entity.name }}
            </div>
          </div>
        </div>

        <!-- food -->
        <div class="flex flex-col border-4 border-food rounded-2xl p-4 max-h-full overflow-hidden">
          <!-- title -->
          <div class="flex items-end gap-2">
            <h1 class="font-heading font-bold text-4xl text-food">Genießen</h1>
            <img src="@/assets/icons/food_establishment.png" class="w-12">
          </div>

          <!-- content -->
          <div class="flex flex-col gap-1 overflow-y-scroll h-full py-2">
            <div v-for="(entity) of getCategory('food')?.entities"
                 :key="entity._id"
                 :class="{'underline font-bold': selectedEntity === entity._id}"
                 @click="highlight(entity)"
            >
              {{ entity.name }}
            </div>
          </div>
        </div>

        <!-- gambling luck and information  -->
        <div class="flex flex-col gap-4 overflow-y-scroll h-full">

          <!-- gambling luck -->
          <div class="flex flex-col flex-grow border-4 border-gambling rounded-2xl p-4 overflow-hidden">
            <!-- title -->
            <div class="flex items-end gap-2">
              <h1 class="font-heading font-bold text-4xl text-gambling">Spielglück & Sonstiges</h1>
              <img src="@/assets/icons/booth.png" class="w-12">
            </div>

            <!-- content -->
            <div class="flex flex-col gap-1 overflow-y-scroll">
              <div v-for="(entity) of getCategory('gambling_others')?.entities"
                   :key="entity._id"
                   :class="{'underline font-bold': selectedEntity === entity._id}"
                   @click="highlight(entity)"
              >
                {{ entity.name }}
              </div>
            </div>
          </div>

          <!-- information -->
          <div class="flex flex-col border-4 border-info rounded-2xl p-4 overflow-hidden h-[30%]">
            <!-- title -->
            <div class="flex items-end gap-2">
              <h1 class="font-heading font-bold text-4xl text-info">Allgemeines</h1>
              <img src="@/assets/icons/info.png" class="w-11">
            </div>

            <!-- content -->
            <div class="flex flex-col gap-1 overflow-y-scroll">
              <div v-for="(entity) of getCategory('info')?.entities"
                   :key="entity._id"
                   :class="{'underline font-bold': selectedEntity === entity._id}"
                   @click="highlight(entity)"
              >
                {{ entity.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {CoreMap} from "@venus-gmbh/map-core";
import axios from "axios";
import EntityComponent from "@/components/Entity.vue";

export default {
  name: 'App',
  components: {EntityComponent},
  data() {
    return {
      map: undefined,
      markers: [],
      markersHidden: false,

      imageLayer: undefined,
      layerHidden: false,

      iconMapping: [
        {
          icon: 'can_throwing',
          types: ['Booth'],
          topics: ['can_throwing']
        },
        {
          icon: 'carousel',
          types: ['Ride'],
          topics: ['carousel'],
        },
        {
          icon: 'action_carousel',
          types: ['Ride'],
          topics: ['action_carousel'],
        },
        {
          icon: 'auto_scooter',
          types: ['Ride'],
          topics: ['auto_scooter'],
        },
        {
          icon: 'dart_throwing',
          types: ['Booth'],
          topics: ['darts']
        },
        {
          icon: 'ferris_wheel',
          types: ['Ride'],
          topics: ['ferris_wheel'],
          size: 1.5
        },
        {
          icon: 'fishing',
          types: ['Booth'],
          topics: ['fishing']
        },
        {
          icon: 'auto_scooter',
          types: ['Booth'],
          topics: ['auto_scooter']
        },
        {
          icon: 'ballon',
          types: ['Booth'],
          topics: ['ballons']
        },
        {
          icon: 'dart_throwing',
          types: ['Booth'],
          topics: ['shooting']
        },
        {
          icon: 'swing',
          types: ['Ride'],
          topics: ['swing'],
          size: 0.75
        },
        {
          icon: 'wine',
          types: ['FoodEstablishment', 'Showman'],
          featuresFoodEstablishment: 'beverages'
        },
        {
          icon: 'food_establishment',
          types: ['FoodEstablishment'],
          featuresFoodEstablishment: 'Imbiss'
        },

        {
          icon: 'beer',
          types: ['BeerCellar']
        },
        {
          icon: 'wc',
          types: ['PublicToilet']
        },
        {
          icon: 'showman',
          types: ['Showman']
        },
        {
          icon: 'food_establishment',
          types: ['FoodEstablishment']
        },
        {
          icon: 'event',
          types: ['Event']
        },
        // Bude
        {
          icon: 'booth',
          types: ['Booth']
        },
        // Fahrgeschäft
        {
          icon: 'ride',
          types: ['Ride']
        },
        {
          icon: 'machine',
          types: ['SlotMachine']
        },
        {
          icon: 'tourist_trip',
          types: ['TouristTrip']
        },
        {
          icon: 'hospital',
          types: ['EmergencyService']
        },
        {
          icon: 'info',
          types: ['CivicStructure', 'InfoPoint']
        },
        {
          icon: 'ec',
          types: ['AutomatedTeller']
        },
        {
          icon: 'music_stage',
          types: ['MusicEvent']
        }
      ],

      categories: [
        {
          name: 'ride',
          icons: ['ride', 'carousel', 'action_carousel', 'auto_scooter', 'ferris_wheel', 'swing']
        },
        {
          name: 'beer',
          icons: ['beer']
        },
        {
          name: 'food',
          icons: ['food_establishment', 'wine']
        },
        {
          name: 'info',
          icons: ['info', 'hospital', 'tourist_trip', 'wc', 'ec']
        },
        {
          name: 'gambling_others',
          icons: ['booth', 'dart_throwing', 'fishing', 'auto_scooter', 'ballon', 'machine', 'event']
        },
      ],
      entities: undefined,
      selectedEntity: undefined,
      resetTimeout: undefined
    }
  },
  mounted() {
    this.createMap();
  },
  methods: {
    async createMap() {
      this.entities = (await axios.post('https://gateway.venus.bayern/api/v1/entities', {
        filter: {
          type: 'filter-link',
          content: '/?projects=fraenkische-schweiz&channels=464',
        },
        result: {
          format: 'json',
          properties: ['name', 'coords', 'address', 'MD_public', 'shortDescription', 'types', 'topics', 'typeIcon', 'typeColor', 'featuresFoodEstablishment'],
          transform: {
            language: 'de-DE',
          },
        },
        options: {
          size: 5000,
        }
      })).data.data.map((rawEntity) => {
        const name = rawEntity?.name?.find((entry) => entry.lang === 'de-DE')?.value ?? '';
        let address, thumbnail, altTextAccessibility = undefined;

        for (const image of rawEntity?.MD_public ?? []) {
          if (!image.fileUsage?.includes('thumbnail'))
            continue;

          const thumbUrl = image.media?.thumbUrl;
          if (!thumbUrl)
            continue;

          thumbnail = thumbUrl ? 'https://webimages.we2p.de/2/' + thumbUrl : undefined;
          if (image?.media?.metadata?.Iptc4xmpCore?.AltTextAccessibility)
            altTextAccessibility = image?.media?.metadata?.Iptc4xmpCore?.AltTextAccessibility[0]?.value ?? `Bild zu ${name}`;
        }

        if (!this.thumbnail && rawEntity.MD_public) {
          thumbnail = 'https://webimages.we2p.de/2/' + rawEntity?.MD_public[0]?.thumbUrl;
          altTextAccessibility = `Bild zu ${name}`;
        }

        if (rawEntity.address) {
          address = "";

          if (rawEntity.address.street)
            address += rawEntity.address.street;

          if (rawEntity.address.zip)
            address += (rawEntity.address.length > 0 ? ', ' : '') + rawEntity.address.zip

          if (rawEntity.address.city)
            address += (rawEntity.address.length > 0 ? ' ' : '') + rawEntity.address.city
        }

        return {
          ...rawEntity,
          name,
          thumbnail,
          address,
          altTextAccessibility,
        }
      });

      this.map = new CoreMap({
        container: 'map', // container id
        style: 'https://tiles.venus.bayern/styles/venus-kellerwald/style.json',
        //center: [10.4541194, 51.1642292],
        //center: [11.07649439104992, 49.728469487190864],
        center: [
          11.0751739759732,
          49.728638968587205
        ],
        pitch: 80,
        bearing: 100,
        zoom: 17,
        maxBounds: [
          [11.069295, 49.720522], [11.090238, 49.732755]
        ],
      });
      const mapLibre = this.map.getMapLibreInstance();

      mapLibre.on('move', () => {
        // reset reset timeout
        this.reset();
      });

      mapLibre.on('click', (e) => {
        console.log('click', e);
      });

      const iconGroups = {};
      mapLibre.on('load', () => {

        // match exact types
        for (const entity of this.entities) {
          let iconDefinition = undefined;

          // exact match
          iconDefinition = this.iconMapping.find((icon) => {
            if (entity.featuresFoodEstablishment) {
              if (Array.isArray(icon.featuresFoodEstablishment))
                return icon.types.includes(entity.types[0]) && icon.featuresFoodEstablishment.find((type) => (entity?.featuresFoodEstablishment ?? []).includes(type));

              return icon.types.includes(entity.types[0]) && icon.featuresFoodEstablishment === (entity?.featuresFoodEstablishment ?? [])[0];
            }

            if (entity.topics && Array.isArray(icon.topics)) {
              return icon.types.includes(entity.types[0]) && icon.topics.find((type) => entity?.topics.includes(type));
            }

            return icon.types.includes(entity.types[0]);
          });

          if (!iconDefinition) {
            // relative match
            iconDefinition = this.iconMapping.find((icon) => {
              return icon.types.some((type) => entity.types.includes(type));
            });
          }

          if (!iconDefinition) {
            iconDefinition = {
              icon: 'marker'
            }
          }

          if (!iconGroups[iconDefinition.icon])
            iconGroups[iconDefinition.icon] = [];

          iconGroups[iconDefinition.icon].push(entity);
        }

        // add sources
        for (const group of Object.keys(iconGroups)) {
          for (const entity of iconGroups[group]) {
            if (!entity.coords)
              continue;

            // add to category
            this.addToCategory(group, entity);

            // add icons
            mapLibre.addSource(`${entity._id}_source`, {
              type: 'geojson',
              data: {
                'type': 'FeatureCollection',
                'features': [{
                  'type': 'Feature',
                  'geometry': {
                    'type': 'Point',
                    'coordinates': entity.coords
                  }
                }]
              }
            });

            mapLibre.addLayer({
              'id': `${entity._id}_layer`,
              'type': 'symbol',
              'source': `${entity._id}_source`,
              'layout': {
                'icon-image': group,
                'icon-allow-overlap': true,
                'icon-size': this.getIcon(group)?.size ?? 0.7
              }
            });

            mapLibre.on('click', `${entity._id}_layer`, (e) => {
              this.highlight(entity);
            });
          }
        }
      });
    },
    highlight(entity) {
      const mapLibre = this.map.getMapLibreInstance();

      // reset currently selected entity
      if (this.selectedEntity) {
        const layer = mapLibre.getLayer(`${this.selectedEntity._id}_layer`);
        const iconName = layer.layout['icon-image'];
        // get icon
        mapLibre.setLayoutProperty(`${this.selectedEntity._id}_layer`, 'icon-size', this.getIcon(iconName)?.size ?? 0.7);
      }

      if (!entity) {
        this.selectedEntity = undefined;
        return;
      }

      // highlight selected entity
      mapLibre.setLayoutProperty(`${entity._id}_layer`, 'icon-size', 1.5);
      this.map.getMapLibreInstance().easeTo({
        center: entity.coords,
        zoom: 18
      });
      this.selectedEntity = entity;

      this.reset();
    },
    reset() {
      if (this.resetTimeout)
        window.clearTimeout(this.resetTimeout);

      this.resetTimeout = window.setTimeout(() => {
        this.highlight(undefined);
        this.map.getMapLibreInstance().easeTo({
          center: this.map.getMapLibreInstance().getCenter(),
          pitch: 80,
          bearing: 100,
          zoom: 17,
        });
        this.resetTimeout = undefined;
      }, 1000 * 10); // 10 seconds
    },
    getIcon(name) {
      return this.iconMapping.find((icon) => icon.icon === name);
    },
    addToCategory(icon, entity) {
      const category = this.categories.find((category) => category.icons.includes(icon));

      if (!category)
        return;

      if (!category.entities)
        category.entities = [];

      category.entities.push(entity);
    },
    getCategory(name) {
      return this.categories.find((category) => category.name === name) ?? [];
    },
    getEntity(entityId) {
      return this.entities.find((entity) => entity._id === entityId);
    },
    zoomIn() {
      this.map.getMapLibreInstance().zoomIn();
      this.reset();
    },
    zoomOut() {
      this.map.getMapLibreInstance().zoomOut();
      this.reset();
    },
  }
}
</script>
<style>
body {
  margin: 0;
}
</style>